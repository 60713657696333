<script>
import api from '@/api'
import tool from '@/tool'
import base from '@/templates/list'
export default {
  directives: {},
  filters: {},
  components: {
  },
  extends: base,
  props: {},
  data () {
    return {
      showExport: false,
      handleTableColumnWidth: 225,
      tableAction: {
        add: { show: tool.checkButton('role-add'), title: this.$t('global.add') },
        remove: { show: tool.checkButton('role-remove'), title: this.$t('global.remove') },
        edit: { show: tool.checkButton('role-edit'), title: this.$t('global.edit') },
        view: { show: false },
        valid: { show: false }
      },
      showList: [{
        copName: 'el-input',
        placeholder: this.$t('list.角色名称'),
        key: 'roleName',
        name: '',
        attrs: ['placeholder']
      }],
      tableTile: [
        {
          key: '0',
          columnLabel: this.$t('global.序号'),
          width: '80',
          type: 'index'
        }, {
          key: '1',
          columnLabel: this.$t('list.角色名称'),
          prop: 'roleName',
          width: ''
        }, {
          key: '2',
          columnLabel: this.$t('list.角色描述'),
          prop: 'roleDesc',
          width: ''
        }, {
          key: '3',
          columnLabel: this.$t('list.创建人'),
          prop: 'createUserName',
          width: ''
        }, {
          key: '4',
          columnLabel: this.$t('list.创建时间'),
          prop: 'creationTime',
          width: ''
        }
      ],
      formInline: {
        name: '',
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
    }
  },
  created () {
  },
  methods: {
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.factorType = this.$refs.Btns ? this.$refs.Btns.factorType : '1'
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    onSubmit () {
      this.loading = true
      api.auth.getAuthRoleRoleList({
        body: {
          page: this.formInline.page.currentPage,
          size: this.formInline.page.pageSize,
          queryParam: {
            ...this.formInline
          }
        }
      }).then(res => {
        this.tableData = res.data.records.map(item => ({
          ...item,
          creationTime: this.$moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') || ''
        }))
        this.formInline.page.total = res.data.total
        this.loading = false
      })
    },

    // 新增
    add () {
      this.$router.push('/authority-management/role/role-add')
    },

    // 删除
    async remove (id) {
      await this.$confirm(this.$t('global.removeTooltip'), {
        type: 'warning'
      })
      await api.auth.getAuthRoleDeleteRole({ query: { id: id } })

      this.toPage(1)
    },

    edit (id) {
      this.$router.push('/authority-management/role/role-list/role-edit/' + id)
    },
    view (id) {
      this.$router.push('/authority-management/role/role-list/role-info/' + id)
    },
  }
}
</script>
